import { InputState, SingleSelectInput } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { Controller } from 'react-hook-form';

import { LabelValue } from '../../../models/SubmitApplication';

interface FormSelectProps {
  name: string;
  label: string;
  options: any[];
  testId?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onOptionSelected?: (value: any) => void;
  isDisabled?: boolean;
  disabledOptions?: string[];
  selectedLabel?: string | number;
  isDefaultSelect?: boolean;
}

export default function SelectInput(props: Readonly<FormSelectProps>) {
  const getOptions = () => {
    if (props.disabledOptions && props.disabledOptions.length > 0) {
      return props.options.map((option: LabelValue<string>) => {
        return { ...option, disabled: props.disabledOptions?.includes(option.value) };
      });
    } else {
      return props.options;
    }
  };

  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => (
        <ThemeProvider
          themeName={ThemeName.FORD_PRO}
          mode={Mode.LIGHT_MODE}
          productName="COCA">
          {' '}
          <SingleSelectInput
            {...props}
            labelProp={{ label: props.label, showRequiredIndicator: false }}
            placeholder={props.placeholder}
            isDisabled={props.options?.length == 0 || props.isDisabled}
            options={getOptions()}
            selected={props.selectedLabel ?? field.value?.label}
            isDefaultSelect={props.isDefaultSelect}
            helperTextProp={
              fieldState.error?.message ? { secondaryProp: { state: InputState.ERROR, helperText: fieldState.error?.message } } : undefined
            }
            onOptionSelect={(value: any) => {
              const index = props.options.findIndex((item) => item.value == value?.value);
              if (index > -1) {
                field.onChange({ label: props.options[index]?.label, value: value?.value });
              }
              if (props.onChange) {
                props.onChange({ label: props.options[index]?.label, value: value?.value });
              }
              if (props.onOptionSelected) {
                props.onOptionSelected({ label: props.options[index]?.label, value: value?.value });
              }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
              field.onBlur();
              if (props.onBlur) {
                props.onBlur(e);
              }
            }}
          />
        </ThemeProvider>
      )}
    />
  );
}
