import jsPDF from 'jspdf';

import { ConfirmationResponse } from '../../../context/appContext';

export const processContent = (htmlContent: { querySelectorAll: (arg0: string) => any }) => {
  if (htmlContent) {
    const container = htmlContent.querySelectorAll('.content-container')[0];
    if (container) {
      container.style.fontSize = '16px';
      container.style.color = 'black';
      const paragraphs = container.querySelectorAll('p');
      paragraphs.forEach((p: { style: { fontSize: string } }) => (p.style.fontSize = '16px'));
    }
    const addressContainer = htmlContent.querySelectorAll('.address-container')[0];
    if (addressContainer) {
      const spans = addressContainer.querySelectorAll('span');
      spans ?? spans.forEach((span: { style: { fontSize: string } }) => (span.style.fontSize = '14px'));
      addressContainer.style.display = 'flex';
      addressContainer.style.flexFlow = 'column';
      addressContainer.style.background = 'white';
      addressContainer.style.fontSize = '14px';
    }

    const ols = htmlContent.querySelectorAll('ol');
    ols.forEach((ol: { style: { paddingInlineStart: string } }) => {
      ol.style.paddingInlineStart = '40px';
    });
    const uls = htmlContent.querySelectorAll('ul');
    uls.forEach((ul: { style: { listStyleType: string } }) => {
      ul.style.listStyleType = 'disc';
    });
  }
};

export const addFordCreditBanner = (fordBlueScreen: string | HTMLImageElement, doc: jsPDF) => {
  doc.addImage(fordBlueScreen, 8, 10, 20, 7);
  doc.setFontSize(5);
  doc.text('P.O.BOX 5420000', 20, 18);
  doc.text('OMAHA NE 68154-8000', 20, 20.5);
};

export const createPdf = async (
  businessContent: HTMLElement,
  guarantorContent: HTMLElement,
  doc: jsPDF,
  confirmation: ConfirmationResponse | undefined,
  fordBlueScreen: string | HTMLImageElement
): Promise<jsPDF> => {
  const pageHeight = doc.internal.pageSize.getHeight();

  if (businessContent && confirmation?.firstName) {
    processContent(businessContent);
    addFordCreditBanner(fordBlueScreen, doc);

    await doc.html(businessContent, {
      callback: () => {},
      margin: [10, 10, 10, 10],
      x: 0,
      y: 18,
      width: 190,
      windowWidth: 1200,
      autoPaging: 'text'
    });

    if (confirmation?.guarantorInfo && guarantorContent) {
      doc.addPage();
      processContent(guarantorContent);
      await doc.html(guarantorContent, {
        callback: () => {},
        margin: [10, 10, 10, 10],
        x: 0,
        y: pageHeight - 5,
        width: 190,
        windowWidth: 1200,
        autoPaging: 'text'
      });
      addFordCreditBanner(fordBlueScreen, doc);
    }
  }

  return doc;
};
