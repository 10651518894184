import { Button, Card } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useRef } from 'react';

import { Dealer, DealerAddress } from '../../../../models/Dealer';
import { trackAnalayticsOnClickEmail, trackAnalayticsOnExit } from '../../../../services/FordProAnalaytics';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX, GOGGLE_MAPS_URL } from '../../../../utils/constants';
import styles from '../Dealer.module.scss';

const FORD_PRO_URL = 'fordpro.com';
const isDealerURL = !window.location?.hostname?.includes(FORD_PRO_URL);

const { Add, CheckmarkFilled, Subtract } = fordProIcons;

interface Props {
  dealerIndex: number;
  dealerData: Dealer;
  isSelected: boolean;
  onSelectHandler: (dealerData: Dealer | undefined) => void;
  selectedDealer: string | undefined;
  providedDealer?: boolean;
}

export const createAddressParams = (address?: DealerAddress): string => {
  let streetAddress = `${address?.street1 ?? ''}`;

  if (address?.street2) streetAddress = `${streetAddress} ${address.street2}`;
  if (address?.street3) streetAddress = `${streetAddress} ${address.street3}`;

  return `${streetAddress} ${address?.city} ${address?.state} ${address?.postalCode}`;
};

export default function DealerCard(props: Readonly<Props>) {
  const { dealerIndex, dealerData, isSelected, onSelectHandler, selectedDealer, providedDealer = false } = props;
  const listRef = useRef<HTMLDivElement | null>(null);
  const phone = dealerData.phone;
  const googleMapUrl = GOGGLE_MAPS_URL + createAddressParams(dealerData?.address);

  const displaySelectableButton = () => {
    return (
      <Button
        aria-label={isSelected ? 'Remove This Dealer' : 'Select This Dealer'}
        aria-describedby={`dealer-name-${dealerData.id}`}
        dataTestId={`select-${dealerIndex}-dealer`}
        onClick={() => onSelectHandler(selectedDealer === dealerData.id ? undefined : dealerData)}
        variant={isSelected ? 'outline' : 'solid'}
        disabled={isButtonDisabled()}
        className={getBtnClassName()}>
        {getButtonContent()}
      </Button>
    );
  };

  const isButtonDisabled = () => {
    if (isDealerURL) {
      return false;
    } else {
      return selectedDealer ? selectedDealer !== dealerData.id : false;
    }
  };

  const getBtnClassName = () => {
    if (isDealerURL) {
      return styles.selectVehicleButton;
    } else {
      return selectedDealer && selectedDealer !== dealerData.id ? styles.selectVehicleButtonDisabled : styles.selectVehicleButton;
    }
  };

  const getButtonContent = () => {
    return isSelected ? (
      <>
        Remove This Dealer <Subtract />
      </>
    ) : (
      <>
        Select This Dealer <Add />
      </>
    );
  };

  const displayContactDealer = () => {
    return (
      <Button
        variant="outline"
        isDisabled={true}
        data-testid="contact-dealer-button">
        Contact Dealer Directly
      </Button>
    );
  };

  const isCommercialVehicleCenter = () => {
    return dealerData?.specialties && dealerData.specialties.indexOf('Commercial Vehicle Center Sales') > -1;
  };

  const trackCallOrMail = () => {
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:choose a dealer`;
    trackAnalayticsOnClickEmail(tag, `${tag}:content action`, `${tag}:content:click to call`);
  };

  const trackOnExit = (url: string) => {
    console.log(url);
    const tag = `${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:choose a dealer`;
    trackAnalayticsOnExit(tag, url);
  };

  return (
    <div>
      <div style={{ borderColor: '#00095b', paddingBottom: 2 }}>
        <Card
          cardType="default"
          titleProp={{
            headingLevel: undefined,
            title: '',
            size: undefined
          }}
          className={styles.dealerCardGrid}>
          <div className={styles.dealerContainer}>
            <div
              data-testid="dealer-card"
              className={styles.dealerCard}>
              <div
                data-testid="dealer-name"
                className={`font-medium font-weight-semibold ${styles.dealerTitle} font-inter`}
                ref={listRef}>
                {dealerData.name}
                {isDealerURL && dealerData.isParticipating && isSelected && <CheckmarkFilled className={styles.selectedDealer} />}
              </div>
              <div
                color="black"
                data-testid="dealer-address"
                className={`font-small font-inter ${styles.dealerTitle}`}>
                {dealerData.address?.street1}
              </div>
              <div className={`font-small font-inter ${styles.dealerTitle}`}>
                {dealerData.address?.city}, {dealerData.address?.state} {dealerData.address?.postalCode}
              </div>
              <a
                href={`tel:${phone}`}
                data-testid="dealer-phone"
                className={styles.dealerPhoneNumber}
                onClick={trackCallOrMail}>
                <div>{phone}</div>
              </a>
              <div
                className={`${styles.commercialVehicleCenter} font-inter`}
                data-testid="dealer-commercial-vehicle-center">
                {isCommercialVehicleCenter() ? 'Commercial Vehicle Center' : ''}
              </div>
              <div className={styles.dealerMap}>
                {dealerData?.distanceMi && (
                  <>
                    <div data-testid="dealer-distance-text">
                      <div className="font-inter">{dealerData?.distanceMi} mi</div>
                    </div>
                    <div>
                      <span>{'|'}</span>
                    </div>
                  </>
                )}
                <div data-testid="dealer-getdirection-link">
                  <a
                    href={googleMapUrl}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    style={{ whiteSpace: 'nowrap', fontWeight: 'fontWeightRegular' }}
                    data-testid="get-directions-link"
                    onClick={() => trackOnExit(googleMapUrl)}>
                    Get Directions
                  </a>
                </div>
                {dealerData?.url && (
                  <>
                    <div
                      style={{
                        fontSize: 16,
                        color: '#000'
                      }}>
                      <span>{'|'}</span>
                    </div>
                    <div data-testid="dealer-website-link-desktop">
                      <a
                        data-testid="visit-website-link"
                        target={'_blank'}
                        href={dealerData.url}
                        rel="noopener noreferrer"
                        style={{ whiteSpace: 'nowrap', fontWeight: 'fontWeightRegular' }}
                        onClick={() => trackOnExit(dealerData.url as string)}>
                        Visit Website
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>{!providedDealer && <div>{dealerData.isParticipating ? displaySelectableButton() : displayContactDealer()}</div>}</div>
          </div>
        </Card>
      </div>
    </div>
  );
}
