import { Grid, Image, Spinner } from '@fcamna/react-library';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import backupPhoto from '../../../../assets/images/photo.png';
import { validateVehiclePhoto } from '../../../../services/VehicleApi';
import styles from '../DetailedVehicle/DetailedVehicle.module.scss';

interface SelectVehicleImageLoaderProps {
  src: string;
}

export default function SelectVehicleImageLoader({ src }: Readonly<SelectVehicleImageLoaderProps>) {
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [validURL, setValidURL] = useState<boolean>(false);
  const { showBoundary } = useErrorBoundary();

  useQuery({
    queryKey: ['vehiclePhoto'],
    enabled: true,
    queryFn: () => validateVehiclePhoto(src),
    onSuccess: (isValid: any) => {
      setValidURL(isValid);
      const image = new window.Image();
      image.onload = () => setIsImageLoaded(true);
      image.src = src;
      setFirstLoad(false);
      return () => {
        image.onload = null;
      };
    },
    onError: () => {
      showBoundary({ name: 'Network error', message: 'Failed to retrieve vehicle image' });
    }
  });

  return (
    <div className="d-flex justify-center w-100">
      {!isImageLoaded && (validURL || firstLoad) ? (
        <Grid className={styles.selectVehicleLoader}>
          <Spinner />
        </Grid>
      ) : (
        <Grid className={styles.vehiclePhoto}>
          {validURL ? (
            <Image
              srcProp={{ url: src, alt: 'Vehicle Photo' }}
              radii="8px"
              ratio="1/1"
              objectFit="fill"
              variant="none"
            />
          ) : (
            <Image
              srcProp={{ url: backupPhoto, alt: 'Vehicle Photo alt' }}
              radii="8px"
              ratio="1/1"
              objectFit="fill"
              variant="none"
            />
          )}
        </Grid>
      )}
    </div>
  );
}
