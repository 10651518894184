import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableInLineAlert, EditableText } from '@fcamna/aem-library';
import { DecrementStepper, IncrementSelector, IncrementStepper, NumberInputField } from '@fcamna/react-library';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { QUANTITY_LIMITS } from '../../../../constants/vehicle.constant';
import useVehicleMSRPCalculator from '../../../../hooks/useVehicleMSRPCalculator';
import { VehicleFormData } from '../../../../models/formData/VehicleFormData';
import styles from '../SelectVehicle.module.scss';

export default function ExpressVehicle() {
  const { setValue } = useFormContext();
  const quantity = useWatch({ name: 'quantity' }) ?? 1;
  const vehicles = useWatch({ name: 'vehicles' }) as VehicleFormData[];
  const isMaxQuantityReached = quantity >= QUANTITY_LIMITS.MAX;
  const isAuthorView = AuthoringUtils.isInEditor();
  const { totalVehicles } = useVehicleMSRPCalculator();

  useEffect(() => {
    setValue('quantity', totalVehicles);
  }, [setValue, totalVehicles]);

  const handleQuantityChange = (value: number) => {
    value > quantity ? updateVehicleIncrease() : updateVehicleDecrease();
    setValue('quantity', value);
    setValue('vehicles', vehicles);
  };

  const updateVehicleIncrease = () => {
    if (vehicles[0].quantity != null) {
      vehicles[0].quantity = vehicles[0].quantity + 1;
    }
  };

  const updateVehicleDecrease = () => {
    const lastVehicle = vehicles[vehicles.length - 1];
    if (lastVehicle?.quantity != null) {
      if (lastVehicle.quantity === 1) {
        vehicles.pop();
      } else {
        lastVehicle.quantity = lastVehicle.quantity - 1;
      }
    }
  };

  return (
    <div>
      <EditableText name="vehicle-purchase-count-text" />
      <div className="my-6">
        <IncrementSelector
          value={quantity}
          labelProp={{ label: 'Total Quantity' }}
          onChange={handleQuantityChange}>
          <DecrementStepper isDisabled={quantity == QUANTITY_LIMITS.MIN} />
          <NumberInputField />
          <IncrementStepper isDisabled={isMaxQuantityReached} />
        </IncrementSelector>
      </div>
      {(isMaxQuantityReached || isAuthorView) && (
        <div className="inline-alert-no-body">
          <EditableInLineAlert
            name="express-max-vehicle-text"
            className={styles.maxQuantityAlert}
          />
        </div>
      )}
    </div>
  );
}
