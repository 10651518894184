import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { EditableProgressTracker } from '@fcamna/aem-library';
import { ProgressTrackerVertical } from '@fcamna/react-library';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { FormStepNames, Page } from '../../../models/FormPage';
import useCocaStore from '../../../store';
import ProgressionButtons from '../../common/Buttons/ProgressionButton/ProgressionButton';
import ErrorFallBack from '../../ErrorFallBack';
import BusinessInformation from '../businessInformation/BusinessInformation';
import BusinessInformationReadOnly from '../businessInformation/BusinessInformationReadOnly';
import ContactInformation from '../contactInformation/ContactInformation';
import ContactInformationReadOnly from '../contactInformation/ContactInformationReadOnly';
import Dealer from '../dealer/Dealer';
import DealerReadOnly from '../dealer/DealerReadOnly';
import GuarantorInformation from '../guarantorInformation';
import GuarantorInformationReadOnly from '../guarantorInformation/GuarantorInformationReadOnly';
import SelectVehicle from '../selectVehicle/SelectVehicle';
import SelectVehicleReadOnly from '../selectVehicle/SelectVehicleReadOnly';
import styles from './ApplicationFormStepper.module.scss';

interface IStepTracker {
  name: string;
}

interface FormStep {
  page: Page;
  formName: FormStepNames;
  label: string;
  component: React.ReactNode;
  readOnlyComponent: React.ReactNode;
}

const formSteps: FormStep[] = [
  {
    page: Page.VEHICLE,
    formName: 'vehicles',
    label: 'Select Vehicle(s)',
    component: <SelectVehicle />,
    readOnlyComponent: <SelectVehicleReadOnly />
  },
  {
    page: Page.DEALER,
    formName: 'dealer',
    label: 'Choose A Dealer',
    component: <Dealer />,
    readOnlyComponent: <DealerReadOnly />
  },
  {
    page: Page.CONTACT,
    formName: 'applicantInformation',
    label: 'Contact Information',
    component: <ContactInformation />,
    readOnlyComponent: <ContactInformationReadOnly />
  },
  {
    page: Page.BUSINESS,
    formName: 'businessInfo',
    label: 'Business Information',
    component: <BusinessInformation />,
    readOnlyComponent: <BusinessInformationReadOnly />
  },
  {
    page: Page.GUARANTOR_INFORMATION,
    formName: 'guarantorInfo',
    label: 'Guarantor Information',
    component: <GuarantorInformation />,
    readOnlyComponent: <GuarantorInformationReadOnly />
  }
];

const ApplicationFormStepper = ({ name }: IStepTracker) => {
  const isAuthorView = AuthoringUtils.isInEditor();
  const [activeStep, setActiveStep] = useState(1);
  const updateRootPage = useCocaStore((state) => state.updateRootPage);

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleModify = (index: number) => {
    setActiveStep(index);
  };

  const handleReview = () => {
    updateRootPage('/review');
    window.scrollTo({ top: 0 });
  };

  const isLastStep = (checkStep: FormStep) => {
    return formSteps.indexOf(checkStep) === formSteps.length - 1;
  };

  if (isAuthorView) {
    return <EditableProgressTracker name={name} />;
  }

  return (
    <div className={styles.st}>
      <ProgressTrackerVertical
        type="progress"
        activeStep={activeStep}
        className="progress-tracker font-inter"
        onEdit={(_event, step) => {
          handleModify(step);
        }}>
        {formSteps.map((currentStep, index) => (
          <ProgressTrackerVertical.Step
            key={currentStep?.label}
            label={currentStep.label}
            activeStep={activeStep}>
            <ErrorBoundary FallbackComponent={ErrorFallBack}>
              {activeStep > index + 1 && <div>{<div>{currentStep.readOnlyComponent}</div>}</div>}
              {activeStep === index + 1 && (
                <>
                  {currentStep.component}
                  <ProgressionButtons
                    step={currentStep.formName}
                    totalSteps={formSteps.length}
                    activeStep={activeStep}
                    onNextClick={isLastStep(currentStep) ? handleReview : handleNext}
                    onBackClick={index !== 0 ? handleBack : undefined}
                  />
                </>
              )}
            </ErrorBoundary>
          </ProgressTrackerVertical.Step>
        ))}
      </ProgressTrackerVertical>
    </div>
  );
};

export default ApplicationFormStepper;
