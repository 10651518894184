import { EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { InputState } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { useMask } from '@react-input/mask';
import { Controller } from 'react-hook-form';

interface AEMInputProps extends TextInputProps {
  name: string;
  aemName: string;
  type?: string;
  optionalText?: string;
  mask?: string;
}

interface InputProps extends AEMInputProps {
  field: any;
  fieldState: any;
  helperText?: any;
}

export default function AEMInput(props: Readonly<AEMInputProps>) {
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => (
        <Input
          field={field}
          fieldState={fieldState}
          helperTextProp={
            fieldState.error?.message ? { secondaryProp: { state: InputState.ERROR, helperText: fieldState.error?.message } } : undefined
          }
          {...props}
        />
      )}></Controller>
  );
}

function Input(props: Readonly<InputProps>) {
  const inputRef = useMask({ mask: props?.mask, replacement: { _: /\d/ } });

  return (
    <ThemeProvider
      themeName={ThemeName.FORD_PRO}
      mode={Mode.LIGHT_MODE}
      productName="COCA">
      <EditableTextInput
        {...props}
        type={props.type}
        name={props.aemName}
        {...(props.mask && { inputRef: inputRef })}
        inputState={props.fieldState.error != undefined ? 'error' : 'none'}
        onChange={(e) => {
          props.field.onChange(e);
        }}
        value={props.field.value || ''}
        onBlur={() => props.field.onBlur()}
        className="input-wrapper"
      />
    </ThemeProvider>
  );
}
