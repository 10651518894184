import { EditableButton, EditableText } from '@fcamna/aem-library';
import { Divider, Image } from '@fcamna/react-library';

import protectedSvg from '../assets/images/info-protected.svg';
import ApplicationFormStepper from '../components/apply/applicationFormStepper';
import TeAlert from '../components/common/TeAlert';
import useCocaStore from '../store';
import AEMRoute from '../utils/aem/aemRoute';

export default function Apply() {
  const updateRootPage = useCocaStore((state) => state.updateRootPage);

  const handleApplyRoute = () => {
    updateRootPage('/home');
    window.scrollTo({ top: 0 });
  };
  return (
    <AEMRoute route={`${process.env.AEM_APPLY_PAGE}`}>
      <div className="layout-container">
        <EditableButton
          className="cancel-btn"
          onClick={handleApplyRoute}
          name="cancel-btn"
        />
        <div
          data-testid="cocaForm"
          className="mt-6">
          <EditableText name="main-heading" />
          <div className="mt-5">
            <Divider />
          </div>
          <div className="my-12">
            <EditableText name="main-para" />
            <TeAlert />
            <div className="d-flex align-baseline mt-4 gap-x-1">
              <div className="protected-svg">
                <Image
                  srcProp={{ url: protectedSvg, alt: 'protected' }}
                  variant="none"
                />
              </div>
              <EditableText name="trusted-text" />
            </div>
          </div>
          <ApplicationFormStepper name="coca-tracker" />
        </div>
      </div>
    </AEMRoute>
  );
}
