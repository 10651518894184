import { Button, Grid } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { useParams } from 'react-router-dom';

import useBreakpoint from '../../../../hooks/useBreakPoint';
import styles from '../../Review.module.scss';
import ModifySuccess from '../modifySuccess/ModifySuccess';

const { Edit } = fordProIcons;

export interface ReviewHeaderProps {
  title: string;
  handleModify: () => void;
  currentUpdatedStep: boolean;
}

function ReviewHeader(props: Readonly<ReviewHeaderProps>) {
  const { title, handleModify, currentUpdatedStep } = props;
  const breakPoints = useBreakpoint();
  const isMobile = breakPoints?.xs;
  const { dealerId } = useParams();
  const isDealerUrl = dealerId && props?.title == 'Dealer';

  return (
    <Grid className={styles.cardheader}>
      {currentUpdatedStep && (
        <Grid.Item
          s={12}
          m={12}
          l={12}
          xl={12}>
          {<ModifySuccess title={title} />}
        </Grid.Item>
      )}
      <Grid.Item
        s={12}
        m={6}
        l={6}
        xl={6}>
        <p className={`${styles.cardTitle} font-ford-f1-semibold`}>{title}</p>
      </Grid.Item>
      {!isMobile && (
        <Grid.Item
          s={6}
          m={6}
          l={6}
          xl={6}
          className={styles.modifyButton}>
          {!isDealerUrl && (
            <Button
              onClick={() => handleModify()}
              variant="text"
              leftIcon={<Edit />}
              style={{ padding: 0 }}>
              Modify
            </Button>
          )}
        </Grid.Item>
      )}
    </Grid>
  );
}

export default ReviewHeader;
