import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Page } from '../../../../models/FormPage';
import { SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { SessionStorageUtils } from '../../../../utils/SessionStorageUtils';
import BusinessInformation from '../../../apply/businessInformation/BusinessInformation';
import ContactInformation from '../../../apply/contactInformation/ContactInformation';
import Dealer from '../../../apply/dealer/Dealer';
import GuarantorInformation from '../../../apply/guarantorInformation/GuarantorInformation';
import SelectVehicle from '../../../apply/selectVehicle/SelectVehicle';
import ReviewModifyButtons from '../reviewModifyButton/ReviewModifyButtons';

interface Props {
  handleUpdate: (step: Page) => void;
  handleModify: (step: Page) => void;
  page: Page;
  setUpdatedStep: React.Dispatch<React.SetStateAction<Page>>;
}

export default function ReviewModify(props: Readonly<Props>) {
  const { handleUpdate, handleModify, page, setUpdatedStep } = props;
  const { reset } = useFormContext<SubmitApplicationFormData>();

  const handleCancel = async () => {
    const formData = SessionStorageUtils.getParseItem('formData');
    formData && reset(formData);
    setUpdatedStep(Page.REVIEW);
    handleModify(Page.REVIEW);
  };

  return (
    <div className="font-ford-f1-semibold">
      {page === Page.VEHICLE && (
        <>
          <div className="mb-4">
            <b>Step 1:</b> Select Vehicle
          </div>
          <SelectVehicle />
          <ReviewModifyButtons
            handleCancel={handleCancel}
            handleUpdate={() => {
              handleUpdate(Page.REVIEW);
            }}
            step={'vehicles'}
          />
        </>
      )}
      {page === Page.DEALER && (
        <>
          <div className="mb-4">
            <b>Step 2:</b> Choose A Dealer
          </div>
          <Dealer />
          <ReviewModifyButtons
            handleCancel={handleCancel}
            handleUpdate={() => {
              handleUpdate(Page.REVIEW);
            }}
            step={'dealer'}
          />
        </>
      )}
      {page === Page.CONTACT && (
        <>
          <div className="mb-4">
            <b>Step 3:</b> Contact Information
          </div>
          <ContactInformation />
          <ReviewModifyButtons
            handleCancel={handleCancel}
            handleUpdate={() => handleUpdate(Page.REVIEW)}
            step={'applicantInformation'}
          />
        </>
      )}
      {page === Page.BUSINESS && (
        <>
          <div className="mb-4">
            <b>Step 4:</b> Business Information
          </div>
          <BusinessInformation />
          <ReviewModifyButtons
            handleCancel={handleCancel}
            handleUpdate={() => handleUpdate(Page.REVIEW)}
            step={'businessInfo'}
          />
        </>
      )}
      {page === Page.GUARANTOR_INFORMATION && (
        <>
          <div className="mb-4">
            <b>Step 5:</b> Guarantor Information
          </div>
          <GuarantorInformation />
          <ReviewModifyButtons
            handleCancel={handleCancel}
            handleUpdate={() => handleUpdate(Page.REVIEW)}
            step={'guarantorInfo'}
          />
        </>
      )}
    </div>
  );
}
