import { EditableButton } from '@fcamna/aem-library';
import { Grid, Spinner } from '@fcamna/react-library';
import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { fordProIcons } from '@fcamna/shared-icons';
import { useQuery } from '@tanstack/react-query';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAppContext } from '../../../context/appContext';
import useAdobeAnalytics from '../../../hooks/useAdobeAnalaytics';
import { Dealer as Dealers } from '../../../models/Dealer';
import { SubmitApplicationFormData } from '../../../models/SubmitApplication';
import { fetchDealersByPostalCode } from '../../../services/DealerApi';
import { AEMSubRoute } from '../../../utils/aem/aemRoute';
import { FORD_PRO_ADOBE_PAGE_NAME_PREFIX } from '../../../utils/constants';
import { SessionStorageUtils } from '../../../utils/SessionStorageUtils';
import AEMInput from '../../common/AEM/Input/AEMInput';
import styles from './Dealer.module.scss';
import DealerCardGrid from './DealerCardGrid';

const FORD_PRO_URL = 'fordpro.com';

const { Search, CancelCloseFilled, Warning } = fordProIcons;

export default function Dealer() {
  const [dealers, setDealers] = useState<Dealers[]>([]);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [isLoadingModel, setIsLoadingModel] = useState<boolean>(false);
  const dealerWatcher = useWatch({ name: 'dealer' });
  const {
    getValues,
    setValue,
    formState: { errors },
    clearErrors
  } = useFormContext<SubmitApplicationFormData>();
  const { isProvidedDealer } = useAppContext();
  const { showBoundary } = useErrorBoundary();

  useAdobeAnalytics(`${FORD_PRO_ADOBE_PAGE_NAME_PREFIX}:choose a dealer`);

  const isDealerURL = !window.location?.hostname?.includes(FORD_PRO_URL);

  useEffect(() => {
    setValue('isDealerUrl', isDealerURL);
    SessionStorageUtils.setParsedItem('formData', getValues());
  }, [getValues, isDealerURL, setValue]);

  const dealerQuery = useQuery({
    queryKey: ['dealerData'],
    enabled: false,
    networkMode: 'always',
    queryFn: () => fetchDealersByPostalCode(dealerWatcher?.zipCode ?? ''),
    retry: 1,
    onSuccess: (data) => {
      setDataFetched(true);
      setDealers(data);
    },
    onError: () => {
      showBoundary({ name: 'Network error', message: 'Failed to retrieve dealers' });
    },
    onSettled: () => setIsLoadingModel(false)
  });

  const handleSearch = () => {
    setIsLoadingModel(true);
    dealerQuery.refetch();
  };

  const clearSearch = () => {
    setValue('dealer.zipCode', '');
    setDealers([]);
    setDataFetched(false);
    setValue('dealer.data', undefined);
  };

  useEffect(() => {
    const dealer = getValues('dealer.data');
    if (dealer) {
      setDealers([dealer]);
    }
  }, [getValues]);

  const updateSelectedPaCode = (dealerData: Dealers | undefined) => {
    setValue('dealer.data', dealerData);
    clearErrors('dealer');
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const zipCode = getValues('dealer.zipCode');
    e.key === 'Enter' && zipCode?.length == 5 && handleSearch();
  };

  const hasDealers = () => {
    return dealers.length > 0;
  };

  return (
    <AEMSubRoute
      route={`${process.env.AEM_APPLY_PAGE}`}
      subRoute={`${process.env.AEM_DEALER}`}>
      <ThemeProvider
        themeName={ThemeName.FORD_PRO}
        mode={Mode.LIGHT_MODE}
        productName="COCA">
        <div>
          {!isProvidedDealer && (
            <Grid>
              <Grid.Item
                xl={4}
                l={5}
                m={8}
                s={12}>
                <AEMInput
                  name={'dealer.zipCode'}
                  aemName="dealer-zipcode"
                  mask="_____"
                  startAdornmentProp={{ startAdornment: <Search /> }}
                  endAdornmentProp={{ endAdornment: dealerWatcher?.zipCode && <CancelCloseFilled onClick={clearSearch} /> }}
                  onKeyUpCapture={handleKeyPress}
                />

                <div className={styles.searchWrapper}>
                  <EditableButton
                    name="search-dealer"
                    className={`${styles.searchDealerBtn} cf-field`}
                    onClick={() => handleSearch()}
                    disabled={dealerWatcher?.zipCode?.length !== 5 || isLoadingModel}
                  />
                  {isLoadingModel && <Spinner className="cf-field" />}
                </div>
              </Grid.Item>
            </Grid>
          )}

          <div
            className={styles.dealerGrid}
            style={{ marginBlock: isProvidedDealer ? 0 : 32 }}>
            {hasDealers() && (
              <DealerCardGrid
                dealers={dealers}
                selectedDealer={dealerWatcher?.data}
                onDealerSelect={updateSelectedPaCode}
              />
            )}
            {!hasDealers() && dataFetched && (
              <Grid className={styles.nodealerFound}>
                <Grid.Item>
                  <div className="color-neutral-700 font-xsmall font-weight-medium font-inter">
                    No dealers found, please try another zip code.
                  </div>
                </Grid.Item>
              </Grid>
            )}
          </div>
          {errors.dealer?.data !== undefined && (
            <div className="field-error">
              <Warning
                width={16}
                height={16}
              />
              {errors.dealer.data.id?.message}
            </div>
          )}
        </div>
      </ThemeProvider>
    </AEMSubRoute>
  );
}
