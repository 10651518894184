import { EditableButton, EditableText } from '@fcamna/aem-library';
import { Divider, Image } from '@fcamna/react-library';

import protectedSvg from '../assets/images/info-protected.svg';
import styles from '../components/review/Review.module.scss';
import ReviewForm from '../components/review/reviewForm';
import useCocaStore from '../store';
import AEMRoute from '../utils/aem/aemRoute';

export default function Review() {
  const updateRootPage = useCocaStore((state) => state.updateRootPage);

  const handleApplyRoute = () => {
    updateRootPage('/apply');
    window.scrollTo({ top: 0 });
  };

  return (
    <AEMRoute route={`${process.env.AEM_REVIEW}`}>
      <div className="layout-container">
        <EditableButton
          onClick={handleApplyRoute}
          className="cancel-btn"
          name="cancel-btn"
          directProps={{ icon: 'caretLeft', iconPosition: 'left', size: 'small' }}
        />
        <div className="mt-6">
          <EditableText
            name="review-title"
            className={styles.applicationTitle}
          />
          <div className="divider">
            <Divider />
          </div>
          <EditableText
            name="trusted-text"
            className="rich-editor font-xsmall"
          />
          <div className="d-flex align-center my-4 gap-x-1 font-inter">
            <div className="protected-svg">
              <Image
                srcProp={{ url: protectedSvg, alt: 'protected' }}
                variant="none"
              />
            </div>
            <EditableText
              name="trusted-icon-text"
              className="rich-editor font-xsmall"
            />
          </div>
          <ReviewForm />
        </div>
      </div>
    </AEMRoute>
  );
}
