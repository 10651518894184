import { EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { InputState } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { type InputMask } from 'imask';
import { Dispatch, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIMask } from 'react-imask';

const { Fpo, Show } = fordProIcons;

interface AEMInputProps extends TextInputProps {
  name: string;
  aemName: string;
  type?: string;
  optionalText?: string;
  mask?: string;
}

interface InputProps extends AEMInputProps {
  field: any;
  fieldState: any;
  helperText?: any;
}

export default function SsnInput(props: Readonly<AEMInputProps>) {
  const {
    ref,
    setUnmaskedValue,
    setValue
  }: {
    ref: React.MutableRefObject<HTMLInputElement | null>;
    setUnmaskedValue: Dispatch<InputMask['unmaskedValue']>;
    setValue: Dispatch<InputMask['value']>;
  } = useIMask({
    mask: '000-00-0000',
    overwrite: true
  });

  const formatPastedValue = (ssn: string) => {
    ssn = ssn.replace(/\D/g, '');
    if (ssn.length <= 3) {
      return ssn;
    } else if (ssn.length == 4) {
      return ssn.replace(/(\d{3})(\d)/, '$1-$2');
    } else if (ssn.length >= 5 && ssn.length <= 8) {
      return ssn.replace(/(\d{3})(\d{2})(\d*)/, '$1-$2-$3');
    } else if (ssn.length == 9) {
      return ssn.replace(/(\d{4})(\d{2})(\d{3})/, '$1-$2-$3');
    } else {
      return ssn.replace(/(\d{3})(\d{2})(\d{4})(\d*)/, '$1-$2-$3');
    }
  };

  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => (
        <Input
          field={field}
          inputRef={ref}
          defaultValue={field.value}
          fieldState={fieldState}
          maxLength={11}
          helperTextProp={
            fieldState.error?.message ? { secondaryProp: { state: InputState.ERROR, helperText: fieldState.error?.message } } : undefined
          }
          {...props}
          onChange={(e) => {
            field.onChange(e);
          }}
          onPaste={(event) => {
            event.preventDefault();
            const pastedData = event.clipboardData.getData('Text');
            const formattedData = formatPastedValue(pastedData);
            setUnmaskedValue(formattedData);
            setValue(formattedData);
            field.onChange(formattedData);
          }}
          onBlur={() => field.onBlur()}
        />
      )}
    />
  );
}

function Input({ onChange, onBlur, aemName, ...props }: Readonly<InputProps>) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <EditableTextInput
      {...props}
      startAdornmentProp={{ startAdornment: <Fpo /> }}
      endAdornmentProp={{ endAdornment: <Show onClick={() => setShowPassword(!showPassword)} /> }}
      type={showPassword ? 'text' : 'password'}
      inputState={props.fieldState.error != undefined ? 'error' : 'none'}
      name={aemName}
      onChange={onChange}
      onBlur={onBlur}
      className="input-wrapper"
    />
  );
}
