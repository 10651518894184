import { AEMPage } from '@fcamna/aem-library';
import { Mode, ThemeName } from '@fcamna/shared-component-foundations';
import { ReactNode } from 'react';

import { useAEMBasePath, useRootModel } from './aemHOC';

export const AEMRoot = ({ children }: { children: ReactNode }) => {
  const basePath = useAEMBasePath();

  const { model } = useRootModel();

  if (!model) {
    return '';
  }

  return (
    <AEMPage
      pagePath={basePath}
      aemHost={process.env.AEM_PUBLISHER_URL}
      themeName={ThemeName.FORD_PRO}
      mode={Mode.LIGHT_MODE}>
      {children}
    </AEMPage>
  );
};

function AEMRoute({ route, children }: Readonly<{ route: string; children: ReactNode }>) {
  const basePath = useAEMBasePath();
  return (
    <AEMPage
      pagePath={`${basePath}${route}`}
      themeName={ThemeName.FORD_PRO}
      mode={Mode.LIGHT_MODE}>
      {children}
    </AEMPage>
  );
}

export function AEMSubRoute({ route, subRoute, children }: Readonly<{ route: string; subRoute: string; children: ReactNode }>) {
  const basePath = useAEMBasePath();
  return <AEMPage pagePath={`${basePath}${route}${subRoute}`}>{children}</AEMPage>;
}

export default AEMRoute;
