import { EditableTextInput, TextInputProps } from '@fcamna/aem-library';
import { IInfoProp, InputState } from '@fcamna/react-library';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface AEMInputProps extends TextInputProps {
  name: string;
  aemName: string;
  type?: string;
  optionalText?: string;
  mask?: string;
}

interface InputProps extends AEMInputProps {
  field: any;
  fieldState: any;
  helperText?: any;
  infoProp?: IInfoProp;
}

export default function CurrencyInput(props: Readonly<AEMInputProps>) {
  return (
    <Controller
      name={props.name}
      render={({ field, fieldState }) => (
        <NumericFormat
          prefix={'$'}
          customInput={Input}
          thousandSeparator={true}
          decimalScale={0}
          maxLength={8}
          allowNegative={false}
          onValueChange={(values) => {
            field.onChange(values);
          }}
          onBlur={() => {
            field.onBlur();
          }}
          value={field.value?.formattedValue}
          name={props.name}
          field={field}
          fieldState={fieldState}
          infoProp={props.infoProp}
          aemName={props.aemName}
          placeholder={props.placeholder}
          className={props.className}
          helperTextProp={
            (fieldState.error as any)?.floatValue?.message
              ? { secondaryProp: { state: InputState.ERROR, helperText: (fieldState.error as any)?.floatValue?.message } }
              : undefined
          }
          inputState={fieldState.error != undefined ? 'error' : 'none'}
        />
      )}
    />
  );
}

function Input({ onChange, onBlur, aemName, ...props }: Readonly<InputProps>) {
  return (
    <EditableTextInput
      {...props}
      name={aemName}
      onChange={onChange}
      onBlur={onBlur}
      infoProp={props.infoProp}
      className="input-wrapper"
    />
  );
}
