import { EditableButton, EditableText } from '@fcamna/aem-library';
import { PopupTip } from '@fcamna/react-library';
import { fordProIcons } from '@fcamna/shared-icons';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './ButtonGroup.module.scss';

const { Information, WarningFilled } = fordProIcons;

export interface ButtonNameValue<T> {
  aemName: string;
  label: string;
  value: T;
  message?: string;
}

interface ButtonGroupProps {
  name: string;
  aemName: string;
  buttonProps: ButtonNameValue<any>[];
  tooltipProps?: {
    title: string;
    content: ReactNode;
  };
  onClick?: (value: ButtonNameValue<any>) => void;
}

export default function ButtonGroup(props: Readonly<ButtonGroupProps>) {
  const { name, aemName, buttonProps, tooltipProps } = props;
  const [activeItem, setActiveItem] = useState<ButtonNameValue<any>>();
  const { getValues } = useFormContext();

  useEffect(() => {
    const item = getValues(name);
    const activeItem = buttonProps.find((button) => button?.value === item?.value);
    if (activeItem) {
      setActiveItem(activeItem);
    }
  }, [buttonProps, getValues, name]);

  return (
    <div>
      {aemName && (
        <div className={styles.buttonGroupTitleWrapper}>
          <EditableText name={aemName} />
          {tooltipProps && (
            <PopupTip
              placement={'auto'}
              popupTipBody={tooltipProps.content}
              popupTipHeader={tooltipProps.title}>
              <Information className={styles.tooltip} />
            </PopupTip>
          )}
        </div>
      )}
      <Controller
        name={name}
        render={({ field, fieldState }) => (
          <>
            <div className={styles.buttonWrapper}>
              {buttonProps.map((button) => (
                <Fragment key={button.aemName}>
                  <EditableButton
                    name={button.aemName}
                    className={field.value?.value === button.value ? styles.active : ''}
                    onClick={() => {
                      field.onChange(name, { label: button.label, value: button.value });
                      setActiveItem(button);
                      if (props.onClick) {
                        props.onClick(button);
                      }
                    }}
                  />
                </Fragment>
              ))}
            </div>
            <div className="info-message cf-field">{activeItem?.message}</div>
            {fieldState.error !== undefined && (
              <div className="field-error">
                <WarningFilled
                  width={16}
                  height={16}
                />
                {(fieldState.error as any).value?.message}
              </div>
            )}
          </>
        )}
      />
    </div>
  );
}
