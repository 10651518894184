import { EditableButton, EditableText } from '@fcamna/aem-library';
import { Card, Grid, Spinner } from '@fcamna/react-library';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { MSRP_LIMITS, QUANTITY_LIMITS } from '../../../../constants/vehicle.constant';
import { useAppContext } from '../../../../context/appContext';
import { DisplayModel } from '../../../../models/DisplayModel';
import { getDefaultVehicle, VehicleFormData } from '../../../../models/formData/VehicleFormData';
import { LabelValue, SubmitApplicationFormData } from '../../../../models/SubmitApplication';
import { fetchVehicleModels, fetchVehicleTrims, fetchVehicleYears } from '../../../../services/VehicleApi';
import useCocaStore from '../../../../store';
import SelectInput from '../../../common/SelectInput';
import SelectVehicleImageLoader from '../SelectVehicleImageLoader';
import VehicleSelector from '../VehicleSelector';
import styles from './DetailedVehicle.module.scss';

interface Props {
  totalVehicles: number;
  totalMSRP: number;
  updateErrorBoundary: ({ name, message }: { name: string; message: string }) => void;
}

export default function DetailedVehicle(props: Readonly<Props>) {
  const { totalMSRP, totalVehicles, updateErrorBoundary } = props;
  const [activeVehicleIndex, setActiveVehicleIndex] = useState(0);
  const { getValues, setValue, control, trigger } = useFormContext<SubmitApplicationFormData>();
  const { fields, append, remove } = useFieldArray({ name: 'vehicles', control });
  const { retry_count } = useAppContext();
  const isPilotDealer = useCocaStore((state) => state.isPilotDealer);

  const vehicleWatcher = useWatch({ name: 'vehicles' });

  const mapYearOptions = (years: number[]): any[] => {
    const options = years.map((year) => ({ label: year.toString(), value: year.toString() }));
    return options;
  };

  const mapModelOptions = (models: DisplayModel[]): LabelValue<string>[] => {
    return models?.map((model) => ({ label: model?.displayName, value: model.name }) as LabelValue<string>) ?? [];
  };

  const mapTrimOptions = (trims: string[]): LabelValue<string>[] => {
    return trims.map((trim) => ({ label: trim, value: trim }) as LabelValue<string>) ?? [];
  };

  const selectYearQuery = useQuery({
    queryKey: ['yearData'],
    enabled: getValues(`vehicles.${activeVehicleIndex}.yearOptions`) != null,
    queryFn: () => fetchVehicleYears(),
    refetchOnWindowFocus: false,
    networkMode: 'always',
    retry: retry_count,
    onError: () => {
      updateErrorBoundary({ name: 'Network error', message: 'Failed to retrieve vehicle years' });
    }
  });

  const selectModelQuery = useQuery({
    queryKey: ['modelData'],
    enabled: getValues(`vehicles.${activeVehicleIndex}.year`) != null,
    queryFn: () => fetchVehicleModels(getValues(`vehicles.${activeVehicleIndex}.year.value`)),
    refetchOnWindowFocus: false,
    onSuccess: (data) => setValue(`vehicles.${activeVehicleIndex}.modelOptions`, data),
    networkMode: 'always',
    retry: retry_count,
    onError: () => {
      updateErrorBoundary({ name: 'Network error', message: 'Failed to retrieve vehicle models' });
    }
  });

  const selectTrimQuery = useQuery({
    queryKey: ['trimData'],
    enabled: getValues(`vehicles.${activeVehicleIndex}.model`) != null && getValues(`vehicles.${activeVehicleIndex}.model.value`) !== '',
    queryFn: () =>
      fetchVehicleTrims(getValues(`vehicles.${activeVehicleIndex}.year.value`), getValues(`vehicles.${activeVehicleIndex}.model.value`)),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setValue(`vehicles.${activeVehicleIndex}.trimOptions`, data);
    },
    networkMode: 'always',
    retry: retry_count,
    onError: () => {
      updateErrorBoundary({ name: 'Network error', message: 'Failed to retrieve vehicle trims' });
    }
  });

  const handleYear = async (index: number): Promise<void> => {
    resetModel(index);
    resetTrim(index);
    trigger(`vehicles.${index}.year`);
    setValue(`vehicles.${index}.data`, undefined);
    setActiveVehicleIndex(index);
    selectModelQuery.refetch();
    updateDisabledTrimOptions();
  };

  const handleModel = async (index: number): Promise<void> => {
    setValue(`vehicles.${index}.data`, undefined);
    trigger(`vehicles.${index}.model`);
    resetTrim(index);
    if (getValues(`vehicles.${index}.model`) != null) {
      setActiveVehicleIndex(index);
      selectTrimQuery.refetch();
    }
    updateDisabledTrimOptions();
  };

  const resetModel = (vehicleIndex: number): void => {
    setValue(`vehicles.${vehicleIndex}.model`, null);
    setValue(`vehicles.${vehicleIndex}.modelOptions`, []);
  };

  const resetTrim = (vehicleIndex: number): void => {
    setValue(`vehicles.${vehicleIndex}.trim`, null);
    setValue(`vehicles.${vehicleIndex}.trimOptions`, {});
    setValue(`vehicles.${vehicleIndex}.disabledTrimOptions`, []);
  };

  const handleTrim = async (index: number): Promise<void> => {
    const trimData = getValues(`vehicles.${index}.trimOptions`);
    const trim = getValues(`vehicles.${index}.trim.value`);
    trigger(`vehicles.${index}.trim`);

    if (getValues(`vehicles.${index}.trim`) != null && trimData !== undefined) {
      setActiveVehicleIndex(index);
      setValue(`vehicles.${index}.data`, trimData[trim]);
    } else {
      setValue(`vehicles.${index}.data`, undefined);
    }
    updateDisabledTrimOptions();
  };

  const updateDisabledTrimOptions = (): void => {
    const vehicles = getValues(`vehicles`);
    vehicles.forEach((vehicle: VehicleFormData, vehicleIndex: number) => {
      const disabledTrimOptions: string[] = [];
      if (vehicle.year && vehicle.model) {
        const sameYearModelVehicles = getSameYearModelSelectedVehicles(vehicleIndex, vehicle.year, vehicle.model);
        sameYearModelVehicles.forEach((sameVehicle) => sameVehicle.trim && disabledTrimOptions.push(sameVehicle?.trim?.value));
      }
      setValue(`vehicles.${vehicleIndex}.disabledTrimOptions`, disabledTrimOptions);
    });
  };

  const getSameYearModelSelectedVehicles = (index: number, year: LabelValue<number>, model: LabelValue<string>): VehicleFormData[] => {
    const vehicles = getValues('vehicles');
    return vehicles.filter(
      (vehicle: VehicleFormData, vehicleIndex: number) => index !== vehicleIndex && isSameYearModelVehicle(vehicle, year, model)
    );
  };

  const isSameYearModelVehicle = (
    vehicle: VehicleFormData,
    year: LabelValue<number> | undefined,
    model: LabelValue<string> | null
  ): boolean => {
    return vehicle?.year?.value === year?.value && vehicle?.model?.value === model?.value;
  };

  const handleAddVehicle = () => {
    append(getDefaultVehicle());
    const vehicles = getValues('vehicles');
    setActiveVehicleIndex(vehicles.length - 1);
  };

  const handleRemoveVehicle = (index: number) => {
    remove(index);
    setActiveVehicleIndex(index);
    updateDisabledTrimOptions();
  };

  const isMaxMSRPLimitReached = () => {
    return totalMSRP > MSRP_LIMITS.PILOT_DEALER_MAX_LIMIT || totalVehicles >= QUANTITY_LIMITS.MAX;
  };

  const isAddVehicleBtnDisabled = (): boolean => {
    const isLastVehicleNull = vehicleWatcher && vehicleWatcher[vehicleWatcher?.length - 1].data == null;
    if (isPilotDealer) {
      return isLastVehicleNull || isMaxMSRPLimitReached();
    }
    return isLastVehicleNull;
  };

  return (
    <div className={styles.selectVehicleContainer}>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Card
            className={styles.selectVehicleCard}
            cardType="default"
            titleProp={{
              headingLevel: undefined,
              title: '',
              size: undefined
            }}>
            <div>
              <Grid
                gutterWidth={20}
                className={styles.vehicleSelectorWrapper}>
                <Grid.Item
                  xl={4}
                  l={4}
                  m={6}
                  s={12}>
                  <div className={styles.selectWrapper}>
                    {selectYearQuery.isFetching && activeVehicleIndex == index && <Spinner className={styles.spinner} />}
                    <SelectInput
                      label="Year"
                      name={`vehicles.${index}.year`}
                      placeholder="Year"
                      options={mapYearOptions(selectYearQuery.data ?? [])}
                      onChange={() => handleYear(index)}
                      selectedLabel={getValues(`vehicles.${index}.year.label`) ?? ''}
                      isDefaultSelect={true}
                    />
                  </div>
                </Grid.Item>
                <Grid.Item
                  xl={4}
                  l={4}
                  m={6}
                  s={12}>
                  <div className={styles.selectWrapper}>
                    {selectModelQuery.isFetching && activeVehicleIndex == index && <Spinner className={styles.spinner} />}
                    <SelectInput
                      name={`vehicles.${index}.model`}
                      label={'Model'}
                      placeholder="Model"
                      options={mapModelOptions(getValues(`vehicles.${index}.modelOptions`)) ?? []}
                      onChange={() => handleModel(index)}
                      selectedLabel={getValues(`vehicles.${index}.model.label`) ?? ''}
                      isDefaultSelect={true}
                    />
                  </div>
                </Grid.Item>
                <Grid.Item
                  xl={4}
                  l={4}
                  m={6}
                  s={12}>
                  <div className={styles.selectWrapper}>
                    {selectTrimQuery.isFetching && activeVehicleIndex == index && <Spinner className={styles.spinner} />}
                    <SelectInput
                      name={`vehicles.${index}.trim`}
                      label={'Trim'}
                      placeholder="Trim"
                      options={mapTrimOptions(Object.keys(getValues(`vehicles.${index}.trimOptions`) ?? []))}
                      onChange={() => handleTrim(index)}
                      disabledOptions={getValues(`vehicles.${index}.disabledTrimOptions`) ?? []}
                      selectedLabel={getValues(`vehicles.${index}.trim.label`) ?? ''}
                      isDefaultSelect={true}
                    />
                  </div>
                </Grid.Item>
              </Grid>
            </div>
            {vehicleWatcher[index]?.data !== undefined && (
              <Grid className={styles.selectedVehicleContainer}>
                <SelectVehicleImageLoader src={vehicleWatcher[index].data.usUrl} />
                <Grid.Item
                  s={12}
                  m={12}
                  l={12}
                  xl={12}>
                  <div
                    data-testid="vehicle-label"
                    className={'font-weight-bold font-medium font-inter'}>
                    {vehicleWatcher[index].data.year} {vehicleWatcher[index].data.make} {vehicleWatcher[index].data.model}
                    &nbsp;
                    {vehicleWatcher[index].data.trim}
                  </div>
                </Grid.Item>
              </Grid>
            )}
            <VehicleSelector
              vehicleIndex={index}
              handleRemoveVehicle={() => handleRemoveVehicle(index)}
              handleDecreaseQuantity={() => {}}
            />
          </Card>
          <div className={styles.selectVehicleNoteText}>
            <EditableText name="vehicle-select-all-options-note" />
          </div>
        </Fragment>
      ))}
      <EditableButton
        name="add-vehicle"
        className={styles.addVehicle}
        onClick={handleAddVehicle}
        disabled={isAddVehicleBtnDisabled()}
      />
    </div>
  );
}
