import { Mode, ThemeName, ThemeProvider } from '@fcamna/shared-component-foundations';
import { ReactNode } from 'react';

export interface ILayout {
  children: ReactNode;
}

const Layout = ({ children }: ILayout) => {
  return (
    <ThemeProvider
      themeName={ThemeName.FORD_PRO}
      mode={Mode.LIGHT_MODE}
      productName="COCA">
      <div className="rootContainer">
        <div className="layout">{children}</div>
      </div>
    </ThemeProvider>
  );
};

export default Layout;
